import baseColors from '@elegantstack/flow-ui-theme/src/theme/colors'

//Using tailwind preset colors
import colorPreset from '@elegantstack/flow-ui-theme/src/theme/color-preset'

const  rose = {
  50: '#FFF1F2',
  100: '#FFE4E6',
  200: '#FECDD3',
  300: '#FDA4AF',
  400: '#FB7185',
  500: '#F43F5E',
  600: '#E11D48',
  700: '#BE123C',
  800: '#9F1239',
  900: '#881337',
}

const colors = {
  ...baseColors,
  alphaLighter: '#ff8d93',
  alphaLight: '#d0484f',
  alpha: '#a2383d',
  alphaDark: '#74282c',
  alphaDarker: '#65060b'
}

export default colors